'use client';

import { useRouter, useSearchParams } from 'next/navigation';
import React from 'react';

interface ErrorPageProps {
  error: Error;
  reset: () => void;
}

export default function ArtcileBodyErrorPage({ error }: ErrorPageProps) {
  const router = useRouter();
  const searchParams = useSearchParams();

  React.useEffect(() => {
    // TODO: Implement error collection instead of Promise.resolve
    Promise.resolve().finally(() => {
      const params = new URLSearchParams(searchParams);

      params.append('msg', error.message);
      params.append('path', document.location.pathname);

      router.replace(`/serverError?${params.toString()}`);
    });
  }, [error.message, router, searchParams]);

  return null;
}
